import * as React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Card } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHorizontalRule } from "@fortawesome/pro-regular-svg-icons"
import ResourceInfos from "./resourceInfos"
import { GatsbyImage } from "gatsby-plugin-image"
const ResourceCard = ({ name, resource, id }) => {
  const today = new Date().toISOString()
  let count = 50

  return (
    <Card key={id} id={id} className="resources_single">
      <Link to={`/${name.toLowerCase()}/${resource.slug}`}>
        <GatsbyImage
          image={
            resource.featured_image.localFile?.childImageSharp.gatsbyImageData
          }
          alt={resource.featured_image.alternativeText}
          className="resources_single--image"
        />

        <p className="resources_name">{name}</p>
        <Card.Body>
          <ResourceInfos
            labelName={
              resource.labels !== undefined ? resource.labels[0].name : null
            }
            resourceName={name}
            timeToRead={resource.time_to_read}
            formattedDate={
              name === "Webinars"
                ? resource.webinar_date_formatted
                : resource.published_at_formatted
            }
          />
          <h4 className="card-title">
            {resource.title.slice(0, count) +
              (resource.title.length > count ? "..." : "")}
          </h4>
          <p className="dark-text btn btn-link">
            {name === "Webinars"
              ? resource.webinar_date >= today
                ? "Inschrijven"
                : "Terugkrijgen"
              : "Lees meer"}
            <FontAwesomeIcon icon={faHorizontalRule} />
          </p>
        </Card.Body>
      </Link>
    </Card>
  )
}

ResourceCard.propTypes = {
  name: PropTypes.string,
  resource: PropTypes.object,
}

export default ResourceCard
