import * as React from "react"
import PropTypes from "prop-types"
import { Row, Col } from 'react-bootstrap';
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHorizontalRule } from '@fortawesome/pro-regular-svg-icons'

const Author = ({name, description, link, image}) => {
  return(
    <Row className="block-author align-items-center justify-content-between flex-column flex-md-row bordered-top bordered-bottom col-lg-10 col-12 offset-lg-1">
      <Col xs={12} md={3} className="profile-pic mb-3 mb-md-0">
        <GatsbyImage
          image={image.localFile.childImageSharp.gatsbyImageData}
          alt={image.alternativeText}
        /> 
      </Col>
      <Col xs={12} md={9}>
          <h4>{name}</h4>
          <p>{description}</p>
          <a href={`mailto:${link}`} className="btn btn-link">
            Neem contact op
            <FontAwesomeIcon icon={faHorizontalRule} />
          </a>
      </Col>
    </Row>
  )
}
Author.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.object
}

export default Author
