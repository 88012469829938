import * as React from "react"
// import { graphql } from "gatsby"
import {Container, Row, Col} from 'react-bootstrap';
import PropTypes from "prop-types"
import ResourceCard from "./elements/resourceCard"

const RelatedResources = ({data, classes="", title, padding={bottom:80, top:80,left:15,right:15}}) => {
  const relatedResources = data
  return(
    <Container className={`${classes} component related-resources`} style={{padding:`${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`}}>
      <h2 className="text-center">{title}</h2>
      <div className="container resources_overview">
        <Row>
          <Col md={12} className="resources_wrapper">
            {relatedResources.map((resource, i) => {
              let name = resource.id.slice(0, resource.id.lastIndexOf('_'))
              return (
                <ResourceCard 
                  key={i}
                  id={resource.strapiId}
                  name={name}
                  resource={resource}
                />
              )
            })}
          </Col>
        </Row>
      </div>
    </Container>
  )
}

RelatedResources.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array  
}


export default RelatedResources